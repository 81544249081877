import { useEffect, useState } from "react";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import HeaderDown from "./HeaderDown";
import routeslogo from "./../Page/asset/routeslogo.png"
import "./Navbar.css";
import { Link } from "react-router-dom";
import { RiArrowDropDownLine } from "react-icons/ri";
import english from '../Page/asset/America.png';
import german from '../Page/asset/Germany.png';
import french from '../Page/asset/France.png';
import spanish from '../Page/asset/Spain.png';


const Navbar = () => {
  const [isHovered, setIsHovered] = useState(false);
  const [isHovered2, setIsHovered2] = useState(false);
  const [selectedItem, setselectedItem] = useState("home");
  const [isNavOpen, setIsNavOpen] = useState(false); // New state for navbar open/close

  const currentURL = window.location.href;
  let currentURL1 = currentURL.split("/")[currentURL.split("/").length - 1];

  const handleHover = () => {
    setIsHovered(true);
  };
  const handleHover2 = () => {
    setIsHovered(false);
  };
  const handleHover3 = () => {
    setIsHovered2(true);
  };
  const handleHover4 = () => {
    setIsHovered2(false);
  };
  const handleItemClick = (item) => {
    setselectedItem(item);
    setIsNavOpen(false); // Close the navbar after clicking a nav-item
  };

  useEffect(() => {
    if (currentURL1 === "") {
      currentURL1 = "home";
    }
    setselectedItem(currentURL1);
  }, []);

  const menuStyle = {
    color: "#ff6000",
    // borderBottom: "2px solid #ff6000",
  };

  useEffect(() => {
    const handlePopstate = () => {
      let item = "home";
      const currentURL2 = window.location.href;
      if (currentURL2.indexOf("directfleet") > -1) {
        item = "directfleet";
      } else if (currentURL2.indexOf("Locationpage") > -1) {
        item = "Locationpage";
      } else if (currentURL2.indexOf("RentalPolicy") > -1) {
        item = "rentalpolicy";
      } else if (currentURL2.indexOf("ContactUs") > -1) {
        item = "ContactUs";
      } else if (currentURL2.indexOf("TravelAgent") > -1) {
        item = "TravelAgent";
      } else if (currentURL2.indexOf("reservation") > -1) {
        item = "reservation";
      }
      setselectedItem(item);
    };
    // Add a listener for the popstate event
    window.addEventListener('popstate', handlePopstate);
  }, []);

  const [isNavbarOpen, setIsNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setIsNavbarOpen(!isNavbarOpen);
  };

  const closeNavbar = () => {
    setIsNavbarOpen(false);
  };

  return (
    <div>
      <nav
        className="navbar navbar-expand-lg navbar-light bg-white"
        style={{ boxShadow: "0px 0px 3px blue", zIndex:"1" }}
      >
        {/* <button
        className={`navbar-toggler ${isNavbarOpen ? '' : 'collapsed'}`}
        type="button"
        onClick={toggleNavbar}
      >
        <span className="navbar-toggler-icon"></span>
      </button> */}

        <div className="container-fluid">
          <a className="navbar-brand" href="/">
            <img
              src={routeslogo}
              alt="Route's logo"
              style={{
                width: "133px",
              }}
            />
          </a>
          <button
            // className={`navbar-toggler ${isNavbarOpen ? '' : 'collapsed'}`}
            className={`navbar-toggler ${
              isNavbarOpen ? "" : "collapsed"
            } ms-auto`}
            // className="navbar-toggler ms-auto"
            type="button"
            data-bs-toggle="collapse"
            data-bs-target="#navbarScroll"
            aria-controls="navbarScroll"
            aria-expanded="false"
            aria-label="Toggle navigation"
            // onClick={() => setIsNavOpen(!isNavOpen)}
            onClick={toggleNavbar}
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            // className="collapse navbar-collapse"
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            id="navbarScroll"
            style={{ justifyContent: "left" }}
          >
            <ul className="navbar-nav pe-md-1 me-md-1  my-2 my-lg-0 navbar-nav-scroll gap-lg-4 gap-sm-0 ">
              <li
                className={`nav-item ${
                  selectedItem === "home" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link  "
                  aria-current="page"
                  to="/"
                  title="Home"
                  // onClick={() => handleItemClick("home")}
                  // onClick={closeNavbar}
                  onClick={() => {
                    handleItemClick("home");
                    closeNavbar();
                  }}
                  style={selectedItem === "home" ? menuStyle : {}}
                >
                  <i class="fa fa-home" aria-hidden="true"></i> Home
                </Link>
              </li>
              <li
                className={`nav-item ${
                  selectedItem === "directfleet" ? "active" : ""
                }`}
              >
                <Link
                  className="nav-link"
                  to="/directfleet"
                  // onClick={() => handleItemClick("directfleet")}
                  onClick={() => {
                    handleItemClick("directfleet");
                    closeNavbar();
                  }}
                  style={selectedItem === "directfleet" ? menuStyle : {}}
                >
                  <i class="fa fa-car" aria-hidden="true"></i> Our Fleet
                </Link>
              </li>

              <li
                className="nav-item"
                onMouseEnter={handleHover}
                onMouseLeave={handleHover2}
              >
                <Link
                  className="nav-link"
                  style={
                    selectedItem === "Locationpage" ||
                    selectedItem === "rentalpolicy"
                      ? menuStyle
                      : {}
                  }
                >
                  <span className="faLocationDot">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </span> Locations
                </Link>
                <ul className={`dropdown-menu ${isHovered ? "show" : ""}`}>
                  {/* Dropdown menu items go here */}
                  <li
                    className={` ${
                      selectedItem === "Locationpage" ? "active" : ""
                    }`}
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/Locationpage"
                      // onClick={() => handleItemClick("Locationpage")}

                      onClick={() => {
                        handleItemClick("Locationpage");
                        closeNavbar();
                      }}
                      style={selectedItem === "Locationpage" ? menuStyle : {}}
                    >
                      <span className="faLocationDot">
                        <FontAwesomeIcon icon={faLocationDot} />
                      </span> Location
                    </Link>
                  </li>
                  <li
                    className={` ${
                      selectedItem === "rentalpolicy" ? "active" : ""
                    }`}
                  >
                    {" "}
                    <Link
                      className="nav-link"
                      to="/rentalpolicy"
                      // onClick={() => handleItemClick("rentalpolicy")}

                      onClick={() => {
                        handleItemClick("rentalpolicy");
                        closeNavbar();
                      }}
                      style={selectedItem === "rentalpolicy" ? menuStyle : {}}
                    >
                      <i class="fi fi-sr-document-signed"></i> Rental Policy
                    </Link>
                  </li>
                </ul>
              </li>

              <li
                className={`nav-item ${
                  selectedItem === "TravelAgent" ? "active" : ""
                } `}
              >
                <Link
                  className="nav-link"
                  to="/TravelAgent"
                  // onClick={() => handleItemClick("TravelAgent")}

                  onClick={() => {
                    handleItemClick("TravelAgent");
                    closeNavbar();
                  }}
                  style={selectedItem === "TravelAgent" ? menuStyle : {}}
                >
                  <i class="fa fa-user" aria-hidden="true"></i> Travel Agent
                </Link>
              </li>

              <li className="nav-item   d-none ">
                <a
                  className="nav-link"
                  href="/help"
                  style={{
                    fontSize: "17px",
                    // padding: "30px 0",
                    textDecoration: "none",
                    fontWeight: "600",
                    fontFamily: "Arial",
                  }}
                >
                  Help
                </a>
              </li>

              {/* <li className="nav-item pe-md-5"> */}
              <li
                className={`nav-item ${
                  selectedItem === "reservation" ? "active" : ""
                } `}
              >
                <Link
                  className="nav-link"
                  to="/reservation"
                  // onClick={() => handleItemClick("reservation")}

                  onClick={() => {
                    handleItemClick("reservation");
                    closeNavbar();
                  }}
                  style={selectedItem === "reservation" ? menuStyle : {}}
                >
                  <i class="fa fa-book" aria-hidden="true"></i> Your Reservation
                </Link>
              </li>
              {/* <li className="nav-item  d-flex d-sm-none "> */}
              <li
                className={`nav-item ${
                  selectedItem === "ContactUs" ? "active" : ""
                } `}
              >
                <Link
                  className="nav-link"
                  to="/ContactUs"
                  // onClick={() => handleItemClick("ContactUs")}
                  // onClick={closeNavbar}
                  onClick={() => {
                    handleItemClick("ContactUs");
                    closeNavbar();
                  }}
                  style={selectedItem === "ContactUs" ? menuStyle : {}}
                >
                  <i class="fa fa-phone-square" aria-hidden="true"></i> Contact
                  Us
                </Link>
              </li>
            </ul>
          </div>
          <div
            // className="collapse navbar-collapse"
            className={`collapse navbar-collapse ${isNavbarOpen ? "show" : ""}`}
            id="navbarScroll"
            style={{ justifyContent: "right"}}
          >
            <ul className="navbar-nav pe-md-1 me-md-1  my-2 my-lg-0 navbar-nav-scroll gap-lg-4 gap-sm-0 ">
            <li
                className="nav-item"
                onMouseEnter={handleHover3}
                onMouseLeave={handleHover4}
              >
                <Link className="nav-link" >
                  <span className="faLocationDot">
                    <img src={english} width="30" />
                  </span> English <RiArrowDropDownLine style={{ fontSize: '2rem',marginLeft:"-10px" }}/>
                </Link>
                <ul className={`dropdown-menu ${isHovered2 ? "show" : ""}`}>
                  {/* <li >
                    <a className="nav-link" href="/spanish/">
                        <img src={spanish} width="30" /> Español
                    </a>
                  </li> */}
                  <li >
                    <a className="nav-link" href="/french/">
                        <img src={french} width="30" /> Française
                    </a>
                  </li>
                  {/* <li >
                    <a className="nav-link" href="/german/">
                        <img src={german} width="30" />  Deutsch 
                    </a>
                  </li> */}
                </ul>
              </li>
            </ul></div>
          <div className="d-flex d-sm-none">
            <button
              className="btn btn-outline d-none d-md-flex "
              type="submit"
              style={{ backgroundColor: "blue", color: "white" }}
            >
              Reserve Now
            </button>
          </div>
        </div>
      </nav>
      <hr style={{ margin: "0px", color: "rgb(243, 242, 239)" }} />
      <div>
        <HeaderDown />
      </div>
    </div>
  );
};

export default Navbar;
